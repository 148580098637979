// export const environment = {
//   production: true,
//   apiUrl: "http://117.18.126.117:3000/api",
//   imgUrl: "http://117.18.126.117:3000/",
//   encdecPassword: "aocv2@tely360",
//   gmapApiKey: "AIzaSyCSK4xzKawo3EPi6YXHIks6b1KemoIeg_A",
//   languages: ["en", "thai"],
//   isencryption: false,
// };

export const environment = {
  production: true,
  apiUrl: "http://opera.aocv2.com/api",
  imgUrl: "http://opera.aocv2.com/",
  encdecPassword: "aocv2@tely360",
  gmapApiKey: "AIzaSyCSK4xzKawo3EPi6YXHIks6b1KemoIeg_A",
  languages: ["en", "thai"],
  isencryption: false,
};