<div class="row">
  <div class="col-md-6 col-xl-2">
    <app-card
      [hidHeader]="true"
      cardClass="bg-c-blue order-card borderless"
      [isCardFooter]="true"
      footerClass="bg-c-white border-0"
    >
      <h6 class="text-white">
        <b>{{ "dashboard.aoc_users" | translate }}</b>
      </h6>
      <h2 class="text-white">{{ data?.aocUsers?.total }}</h2>
      <i class="card-icon fas fa-user-tie f-36"></i>
      <div class="app-card-footer">
        <p class="m-b-0">
          <span style="color: greenyellow; font-size: medium"
            ><b>{{ data?.aocUsers.isactive }}</b></span
          >
          /
          <span style="color: blue; font-size: medium">
            <b>{{ data?.aocUsers.deactive }}</b></span
          >
        </p>
      </div>
    </app-card>
  </div>
  <div class="col-md-6 col-xl-2">
    <app-card
      [hidHeader]="true"
      cardClass="bg-twitter order-card borderless"
      [isCardFooter]="true"
      footerClass="bg-c-white border-0"
    >
      <h6 class="text-white">
        <b>{{ "dashboard.doctors" | translate }}</b>
      </h6>
      <h2 class="text-white">{{ data?.doctors.total }}</h2>
      <i class="card-icon fas fa-user-md f-36"></i>
      <div class="app-card-footer">
        <p class="m-b-0">
          <span style="color: greenyellow; font-size: medium"
            ><b>{{ data?.doctors.isactive }}</b></span
          >
          /
          <span style="color: blue; font-size: medium">
            <b>{{ data?.doctors.deactive }}</b></span
          >
        </p>
      </div>
    </app-card>
  </div>

  <div class="col-md-6 col-xl-2">
    <app-card
      [hidHeader]="true"
      cardClass="bg-c-green order-card borderless"
      [isCardFooter]="true"
      footerClass="bg-c-white border-0"
    >
      <h6 class="text-white">
        <b>{{ "dashboard.drivers" | translate }}</b>
      </h6>
      <h2 class="text-white">{{ data?.drivers.total }}</h2>
      <i class="card-icon feather icon-users"></i>
      <div class="app-card-footer">
        <p class="m-b-0">
          <span style="color: greenyellow; font-size: medium"
            ><b>{{ data?.drivers.isactive }}</b></span
          >
          /
          <span style="color: blue; font-size: medium">
            <b>{{ data?.drivers.deactive }}</b></span
          >
        </p>
      </div>
    </app-card>
  </div>

  <div class="col-md-6 col-xl-2">
    <app-card
      [hidHeader]="true"
      cardClass=" bg-c-red order-card borderless"
      [isCardFooter]="true"
      footerClass="bg-c-white border-0"
    >
      <h6 class="text-white">
        <b>{{ "dashboard.nurses" | translate }}</b>
      </h6>
      <h2 class="text-white">{{ data?.nurses.total }}</h2>
      <i class="card-icon fas fa-user-nurse f-36"></i>
      <div class="app-card-footer">
        <p class="m-b-0">
          <span style="color: greenyellow; font-size: medium"
            ><b>{{ data?.nurses.isactive }}</b></span
          >
          /
          <span style="color: blue; font-size: medium">
            <b>{{ data?.nurses.deactive }}</b></span
          >
        </p>
      </div>
    </app-card>
  </div>

  <div class="col-md-6 col-xl-2">
    <app-card
      [hidHeader]="true"
      cardClass="bg-linkedin order-card borderless"
      [isCardFooter]="true"
      footerClass="bg-c-white border-0"
    >
      <h6 class="text-white">
        <b>{{ "dashboard.er_staff" | translate }}</b>
      </h6>
      <h2 class="text-white">{{ data?.aocErStaffs.total }}</h2>
      <i class="card-icon fas fa-users f-36"></i>
      <div class="app-card-footer">
        <p class="m-b-0">
          <span style="color: greenyellow; font-size: medium"
            ><b>{{ data?.aocErStaffs.isactive }}</b></span
          >
          /
          <span style="color: blue; font-size: medium">
            <b>{{ data?.aocErStaffs.deactive }}</b></span
          >
        </p>
      </div>
    </app-card>
  </div>

  <div class="col-md-6 col-xl-2">
    <app-card
      [hidHeader]="true"
      cardClass="bg-c-yellow order-card borderless"
      [isCardFooter]="true"
      footerClass="bg-c-white border-0"
    >
      <h6 class="text-white">
        <b>{{ "dashboard.ems_staff" | translate }}</b>
      </h6>
      <h2 class="text-white">{{ data?.aocEmsStaffs.total }}</h2>
      <i class="card-icon fas fa-users f-36"></i>
      <div class="app-card-footer">
        <p class="m-b-0">
          <span style="color: greenyellow; font-size: medium"
            ><b>{{ data?.aocEmsStaffs.isactive }}</b></span
          >
          /
          <span style="color: blue; font-size: medium">
            <b>{{ data?.aocEmsStaffs.deactive }}</b></span
          >
        </p>
      </div>
    </app-card>
  </div>

  <div class="col-md-12 col-xl-4">
    <app-card
      [hidHeader]="true"
      cardClass="support-bar overflow-hidden"
      blockClass="p-0"
      [isCardFooter]="true"
      footerClass="bg-c-white text-white"
    >
      <div class="p-20 pb-0">
        <h2 class="m-0">{{ data?.aocRequestDetail[0].total[0].count }}</h2>
        <span class="text-c-blue"><b>Requests</b></span>
      </div>
      <div class="app-card-footer">
        <div class="row text-center">
          <div class="col bg-c-green">
            <h4 class="m-0 text-white">
              {{ data?.aocRequestDetail[0].completed[0].count }}
            </h4>
            <span>Completed</span>
          </div>
          <div class="col bg-c-red">
            <h4 class="m-0 text-white">
              {{ data?.aocRequestDetail[0].canceled[0].count }}
            </h4>
            <span>Canceled</span>
          </div>
          <div class="col bg-twitter">
            <h4 class="m-0 text-white">
              {{
                data?.aocRequestDetail[0].total[0].count -
                  (data?.aocRequestDetail[0].canceled[0].count +
                    data?.aocRequestDetail[0].completed[0].count +
                    data?.aocRequestDetail[0].assigned[0].count)
              }}
            </h4>
            <span>Ongoing</span>
          </div>
          <div class="col bg-c-red">
            <h4 class="m-0 text-white">
              {{ data?.aocRequestDetail[0].assigned[0].count }}
            </h4>
            <span>Assigned</span>
          </div>
        </div>
      </div>
    </app-card>
  </div>

  <div class="col-md-6 col-xl-2">
    <app-card
      [hidHeader]="true"
      cardClass="bg-c-green order-card borderless"
      [isCardFooter]="true"
      footerClass="bg-c-white border-0"
    >
      <h6 class="text-white">
        <b>Ambulances</b>
      </h6>
      <h2 class="text-white">{{ data?.ambulanceDetail.total }}</h2>
      <i class="card-icon fas fa-ambulance"></i>
      <div class="app-card-footer">
        <p class="m-b-0">
          <span style="color: greenyellow; font-size: medium"
            ><b>{{ data?.ambulanceDetail.isactive }}</b></span
          >
          /
          <span style="color: blue; font-size: medium">
            <b>{{ data?.ambulanceDetail.deactive }}</b></span
          >
        </p>
      </div>
    </app-card>
  </div>

  <div class="col-md-6 col-xl-2">
    <app-card
      [hidHeader]="true"
      cardClass="bg-c-green order-card borderless"
      [isCardFooter]="true"
      footerClass="bg-c-white border-0"
    >
      <h6 class="text-white">
        <b>Hospitals</b>
      </h6>
      <h2 class="text-white">{{ data?.hospitalDetail.total }}</h2>
      <i class="card-icon fas fa-hospital"></i>
      <div class="app-card-footer">
        <p class="m-b-0">
          <span style="color: greenyellow; font-size: medium"
            ><b>{{ data?.hospitalDetail.isactive }}</b></span
          >
          /
          <span style="color: blue; font-size: medium">
            <b>{{ data?.hospitalDetail.deactive }}</b></span
          >
        </p>
      </div>
    </app-card>
  </div>

  <!-- <div class="col-md-12 col-xl-4">
    <app-card
      [hidHeader]="true"
      blockClass="p-0"
      cardClass="flat-card widget-purple-card"
    >
      <div class="row-table">
        <div class="col-sm-3 card-body">
          <i class="fas fa-ambulance"></i>
        </div>
        <div class="col-sm-9">
          <h4>{{ data?.ambulanceDetail.total }}</h4>
          <h6>Ambulances</h6>
        </div>
      </div>
    </app-card>

    <app-card
      [hidHeader]="true"
      blockClass="p-0"
      cardClass="flat-card widget-purple-card"
    >
      <div class="row-table">
        <div class="col-sm-3 card-body">
          <i class="fas fa-hospital"></i>
        </div>
        <div class="col-sm-9">
          <h4>{{ data?.hospitalDetail.total }}</h4>
          <h6>Hospitals</h6>
        </div>
      </div>
    </app-card>
  </div> -->
</div>
