import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class DashboardService {
  constructor(private httpClient: HttpClient) {}

  getDashboardDetail() {
    let apiParam = new HttpParams();
    return this.httpClient.post(
      environment.apiUrl + "/dashboard/getDashboardDetail",
      {}
    );
  }
}
