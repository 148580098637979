<ul class="navbar-nav ml-auto">
  <li>
    <div class="drp-user dropdown" ngbDropdown placement="auto">
      <a href="javascript:" ngbDropdownToggle>
        <i class="fas fa-language"></i> 
      </a>
      <div class=" dropdown-menu-right profile-notification" ngbDropdownMenu>
        <li role="menuitem" *ngFor="let item of languages" (click)="onLanguageChange(item)"><a class="dropdown-item" href="javascript:;">{{item.label}}</a></li>
      </div>
    </div>
  </li>
  <li>
    <div class="drp-user dropdown" ngbDropdown placement="auto">
      <a href="javascript:" ngbDropdownToggle>
        <i class="feather icon-user"></i>
      </a>
      <div class=" dropdown-menu-right profile-notification" ngbDropdownMenu>
        <div class="pro-head">
          <img src="assets/images/aoclogo.png" class="img-radius" alt="User-Profile-Image">
          <span>AocV2</span>
          <a href="javascript:" class="dud-logout" (click)="signOut();" title="Logout">
            <i class="feather icon-log-out"></i>
          </a>
        </div>
        <ul class="pro-body">
          <li><a href="javascript:" class="dropdown-item"><i class="feather icon-user"></i>{{'menu.profile' | translate}}</a></li>
        </ul>
      </div>
    </div>
  </li>
</ul>
