// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
//   apiUrl: "http://localhost:3000/api",
//   imgUrl: "http://localhost:3000/",
//   encdecPassword: "aocv2@tely360",
//   gmapApiKey: "AIzaSyCSK4xzKawo3EPi6YXHIks6b1KemoIeg_A",
//   languages: ["en", "thai"],
//   isencryption: false,
// };

// export const environment = {
//   production: true,
//   apiUrl: "http://117.18.126.117:3000/api",
//   imgUrl: "http://117.18.126.117:3000/",
//   encdecPassword: "aocv2@tely360",
//   gmapApiKey: "AIzaSyCSK4xzKawo3EPi6YXHIks6b1KemoIeg_A",
//   languages: ["en", "thai"],
//   isencryption: false,
// };

export const environment = {
  production: true,
  apiUrl: "https://opera.aocv2.com/api",
  imgUrl: "https://opera.aocv2.com/",
  encdecPassword: "aocv2@tely360",
  gmapApiKey: "AIzaSyCSK4xzKawo3EPi6YXHIks6b1KemoIeg_A",
  languages: ["en", "thai"],
  isencryption: false,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
