import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AdminComponent } from "./theme/layout/admin/admin.component";
import { AuthComponent } from "./theme/layout/auth/auth.component";
import { DashboardComponent } from "./demo/dashboard/dashboard.component";
import { AuthGuardService } from "./helper/guards/auth-guard.service";
import { environment } from "../environments/environment";

const routes: Routes = [
  {
    path: ``,
    component: AuthComponent,
    children: [
      {
        path: "",
        redirectTo: `auth`,
        pathMatch: "full",
      },
      {
        path: `auth`,
        loadChildren: () =>
          import("./authentication/authentication.module").then(
            (module) => module.AuthenticationModule
          ),
      },
    ],
  },
  {
    path: ``,
    component: AdminComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: `dashboard`,
        component: DashboardComponent,
      },
      {
        path: `master-data`,
        loadChildren: () =>
          import("./demo/master-data/master-data.module").then(
            (module) => module.MasterDataModule
          ),
      },
      {
        path: `users`,
        loadChildren: () =>
          import("./demo/users/users.module").then(
            (module) => module.UsersModule
          ),
      },
      {
        path: `equipment`,
        loadChildren: () =>
          import("./demo/equipment/equipment.module").then(
            (module) => module.EquipmentModule
          ),
      },
      {
        path: `patientinfo`,
        loadChildren: () =>
          import("./demo/patientinfo/patientinfo.module").then(
            (module) => module.patientinfoModule
          ),
      },
      {
        path: `ambulances`,
        loadChildren: () =>
          import("./demo/ambulance/ambulance.module").then(
            (module) => module.ambulanceModule
          ),
      },
      {
        path: `tracking-data`,
        loadChildren: () =>
          import("./demo/tracking-data/tracking-data.module").then(
            (module) => module.TrackingDataModule
          ),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
