export class NextConfig {
    public static config = {
      layout: 'vertical', // vertical, horizontal
      subLayout: '', // horizontal-2
      collapseMenu: false,
      layoutType: 'menu-dark', // menu-dark, menu-light, dark
      headerBackColor: 'header-dark', // header-blue, header-red, header-purple, header-info, header-green, header-dark
      rtlLayout: false,
      navFixedLayout: true,
      headerFixedLayout: true,
      boxLayout: false,
    };
  }
  