import { Component, OnInit } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { DashboardService } from "./dashboard.service";
@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  data: any;
  constructor(private dashboardService: DashboardService) {}

  ngOnInit(): void {
    this.dashboard();
  }

  dashboard() {
    this.dashboardService.getDashboardDetail().subscribe((res) => {
      this.data = res["content"].data;
      console.log(this.data);
    });
  }
}
