import {Component, OnInit} from '@angular/core';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../../../../authentication/api.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../../../../environments/environment';
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig]
})

export class NavRightComponent implements OnInit {
  public languages:any = environment.languages.map(e=> { return { 'label': e}});

  constructor(@Inject(DOCUMENT) private document: Document, private apiService: ApiService, private router: Router, private cookieService: CookieService, private translate: TranslateService) { 
    translate.setDefaultLang('en');
    translate.use(this.cookieService.get('lang'));
  }

  ngOnInit() { }

  signOut() {
    this.apiService.signOut().subscribe((res: any)=>{
      if(res.success){
        this.cookieService.deleteAll();
        this.router.navigate(['auth']);
      }
    })
  }

  onLanguageChange(lang: any) {
    this.cookieService.set('lang', String(lang.label));
    this.document.location.reload();
  }
}
