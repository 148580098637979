import { Injectable } from "@angular/core";

export interface NavigationItem {
  id: string;
  title: string;
  type: "item" | "collapse" | "group";
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const NavigationItems = [
  {
    id: "navigation",
    title: "Navigation",
    type: "group",
    icon: "feather icon-align-left",
    children: [
      {
        id: "dashboard",
        title: "Dashboard",
        type: "item",
        url: "/dashboard",
        classes: "nav-item",
        icon: "feather icon-sidebar",
      },
      {
        id: "master-data",
        title: "Master Data",
        type: "collapse",
        icon: "feather icon-menu",
        children: [
          {
            id: "area_management",
            title: "Area Management",
            type: "collapse",
            children: [
              {
                id: "provinces",
                title: "Provinces",
                type: "item",
                url: "/master-data/area-management/provinces",
              },
              {
                id: "districts",
                title: "Districts",
                type: "item",
                url: "/master-data/area-management/districts",
              },
              {
                id: "subdistricts",
                title: "Sub Districts",
                type: "item",
                url: "/master-data/area-management/subdistricts",
              },
            ],
          },
          {
            id: "hospital_management",
            title: "Hospitals",
            type: "collapse",
            children: [
              {
                id: "hospitals",
                title: "Hospitals",
                type: "item",
                url: "/master-data/hospital-management/hospitals",
              },
              {
                id: "zone",
                title: "AOC-Zone",
                type: "item",
                url: "/master-data/hospital-management/zone",
              },
            ],
          },
          {
            id: "medical_devices_management",
            title: "Hospital Devices",
            type: "collapse",
            children: [
              {
                id: "medical_devices",
                title: "Device Type",
                type: "item",
                url: "/master-data/hospital-devices-management/device-type",
              },
              {
                id: "devices",
                title: "Devices",
                type: "item",
                url: "/master-data/hospital-devices-management/devices",
              },
              {
                id: "ip",
                title: "IP(Server) of Devices",
                type: "item",
                url: "/master-data/hospital-devices-management/device-ip",
              },
              {
                id: "type",
                title: "Hospital Devices",
                type: "item",
                url: "/master-data/hospital-devices-management/medical-devices",
              },
            ],
          },
          // {
          //   id: 'communication-equipment-management',
          //   title: 'Communication Management',
          //   type: 'collapse',
          //   children: [
          //     {
          //       id: 'communication_devices_type',
          //       title: 'Communication Devices Type',
          //       type: 'item',
          //       url: '/master-data/communication-equipment-management/device-type',
          //     },
          //     {
          //       id: 'stream_server',
          //       title: 'Stream Server',
          //       type: 'item',
          //       url: '/master-data/communication-equipment-management/equipment-ip',
          //     },
          //     {
          //       id: 'communication_devices',
          //       title: 'Communication Devices',
          //       type: 'item',
          //       url: '/master-data/communication-equipment-management/communication-devices',
          //     },
          //     {
          //       id: 'amb_communication_devices',
          //       title: 'Ambulance Communication Devices',
          //       type: 'item',
          //       url: '/master-data/communication-equipment-management/ambulance-communication-devices',
          //     }
          //   ]
          // },
          {
            id: "ambulances",
            title: "Ambulance Management",
            type: "collapse",
            children: [
              {
                id: "ambulanceCatalog",
                title: "Ambulance Equipment Catalogy",
                type: "item",
                url: "/master-data/ambulance-management/ambulance-catalogy",
              },
              {
                id: "ambulanceequipment",
                title: "Ambulance-Equipment",
                type: "item",
                url: "/master-data/ambulance-management/ambulance-equipment",
              },
              // {
              //   id: "ambulancemaintenance",
              //   title: "Ambulance-Usage-Maintenance",
              //   type: "item",
              //   url: "/master-data/ambulance-management/ambulance-maintenance",
              // },
              {
                id: "ambulancetype",
                title: "Ambulance-Type",
                type: "item",
                url: "/master-data/ambulance-management/ambulance-type",
              },
            ],
          },
          // {
          //   id: 'sambox',
          //   title: 'SAM BOX',
          //   type: 'collapse',
          //   children: [
          //     {
          //       id: 'sanpshopserver',
          //       title: 'Snapshot Server',
          //       type: 'item',
          //       url: '/master-data/sam-box/snapshot-server',
          //     },
          //     {
          //       id: 'streamserver',
          //       title: 'Stream Server',
          //       type: 'item',
          //       url: '/master-data/sam-box/stream-server',
          //     },
          //     {
          //       id: 'obdserver',
          //       title: 'OBD Server',
          //       type: 'item',
          //       url: '/master-data/sam-box/obd-server',
          //     },
          //     {
          //       id: 'archiveserver',
          //       title: 'Archive Server',
          //       type: 'item',
          //       url: '/master-data/sam-box/archive-server',
          //     },
          //   ]
          // },
        ],
      },
      {
        id: "ambulances",
        title: "Ambulances",
        type: "collapse",
        icon: "fa fa-ambulance",
        children: [
          {
            id: "ambulance",
            title: "Ambulance List",
            type: "item",
            url: "/ambulances/ambulance",
          },
          {
            id: "ambulancereport",
            title: "Ambulance-Report",
            type: "item",
            url: "/ambulances/ambulance-report",
          },
          // {
          //   id: 'ambulancemaintenance',
          //   title: 'Ambulance-Usage-Maintenance',
          //   type: 'item',
          //   url: '/ambulances/ambulance-maintenance',
          // },
        ],
      },
      {
        id: "users",
        title: "Users Management",
        type: "collapse",
        icon: "fa fa-users",
        children: [
          {
            id: "aoc_user",
            title: "Aoc-User",
            type: "item",
            url: "/users/aoc_user",
          },
          {
            id: "doctor",
            title: "Doctor",
            type: "item",
            url: "/users/doctor",
          },
          {
            id: "nurse",
            title: "Nurse",
            type: "item",
            url: "/users/nurse",
          },
          {
            id: "paramedic",
            title: "Paramedic",
            type: "item",
            url: "/users/paramedic",
          },
          {
            id: "driver",
            title: "Driver",
            type: "item",
            url: "/users/driver",
          },

          {
            id: "erstaff",
            title: "Er-Staff",
            type: "item",
            url: "/users/er-staff",
          },
          {
            id: "emsstaff",
            title: "Ems-Staff",
            type: "item",
            url: "/users/ems-staff",
          },
          {
            id: "firstresponder",
            title: "First Responder",
            type: "item",
            url: "/users/first-responder",
          },
          {
            id: "volunteer",
            title: "Volunteer",
            type: "item",
            url: "/users/volunteer",
          },
        ],
      },
      // {
      //   id: 'trackingdata',
      //   title: 'Tracking-Data',
      //   type: 'collapse',
      //   icon: 'fa fa-map-marker-alt',
      //   children: [
      //     {
      //       id: 'ambulancetrack',
      //       title: 'Ambulance Tracking',
      //       type: 'item',
      //       url: '/tracking-data/ambulance-track',
      //     },
      //     {
      //       id: 'mobileuser',
      //       title: 'Mobile User',
      //       type: 'item',
      //       url: '/tracking-data/mobile-user',
      //     },
      //     {
      //       id: 'devicestracking',
      //       title: 'Devices Tracking',
      //       type: 'item',
      //       url: '/tracking-data/devices-tracking',
      //     }
      //   ]
      // },
      {
        id: "equipment",
        title: "Equipment",
        type: "collapse",
        icon: "feather icon-activity",
        children: [
          {
            id: "medicalequipment",
            title: "Medical-Equipment",
            type: "item",
            url: "/equipment/medical-equipment",
          },
          {
            id: "glassmac",
            title: "Glass-Mac",
            type: "item",
            url: "/equipment/glass-mac",
          },
        ],
      },
      {
        id: "patientinfo",
        title: "Patient-Info",
        type: "collapse",
        icon: "fa fa-user-injured",
        children: [
          {
            id: "patientmonitor",
            title: "Patient-Monitor",
            type: "item",
            url: "/patientinfo/patient-monitor",
          },
          {
            id: "patientsituation",
            title: "Patient-Situation",
            type: "item",
            url: "/patientinfo/patient-situation",
          },
        ],
      },
    ],
  },
];

@Injectable()
export class NavigationItem {
  public get() {
    return NavigationItems;
  }
}
