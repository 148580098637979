import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  apiUrl: string = environment.apiUrl;

  constructor(private httpClient: HttpClient) {}

  public signIn(credentials) {
    let apiParam = new HttpParams();
    apiParam = apiParam.append("email", credentials.email);
    apiParam = apiParam.append("password", credentials.password);
    return this.httpClient.post(this.apiUrl + "/master/signIn", apiParam);
  }

  public signOut() {
    return this.httpClient.post(this.apiUrl + "/master/signOut", {});
  }
}
